<template>
  <div class="login-modal" style="display: flex; z-index: 10000">
    <div  class="modal1_content-wrapper-long alt mobile-height">
      <div class="modal-content">
        <h4 class="modal_title">You session has expired</h4>
        <div v-if="usesEmail" class="modal_text">Enter your password to continue</div>
        <div id="sign-up-form" class="w-form">
          <form v-if="usesEmail" id="session-form" aria-label="sign-up-form" class="form-3" data-name="sign-up-form"
                data-wf-element-id="07720d4c-da75-2395-cd70-0a29bba94632"
                data-wf-page-id="647494eb02f5ef704f56758a" method="get"
                name="wf-form-sign-up-form">
            <div id="session-error" class="error form-error">This is some text inside of a div block.</div>
            <div v-show="error" class="error" v-bind:style="{'display': error?'block':'none'}">{{ error }}</div>
            <div class="form_group">
              <label class="field-label" for="session-password">Password</label>
              <input id="session-password" class="form-input w-input" data-name="Password 2" maxlength="256"
                     name="password"
                     placeholder="Enter your Password" type="password">
              <div data-lastpass-icon-root="true"
                   style="position: relative !important; height: 0px !important; width: 0px !important; float: left !important;"></div>
            </div>
            <input :disabled="isLoading" :value="isLoading?'Authenticating...':'Log in'"
                   class="button form_button w-button"
                   data-wait="Please wait..." type="submit">
          </form>
          <div>

            <div v-if="usesEmail" class="or_separator">
              <div class="_1px_div_line"></div>
              <div class="text-size-small or_separator_text">Or</div>
              <div class="_1px_div_line"></div>
            </div>
            <login-with-google-component :on-success="handleSuccess"></login-with-google-component>
          </div>
        </div>
      </div>
    </div>
    <div class="modal1_background-overlay color"></div>
  </div>
</template>
<script>
import {reInitializedWebflowInteractions} from "@/misc/utils";
import $store from "@/store";

var $ = window.$;
export default {
  props: {
    onSuccess: Function,
  },
  data() {
    return {
      isLoading: false,
      error: "",
    };
  },
  computed: {
    $store() {
      return $store;
    },
    usesEmail() {
      return $store.state.auth.user?.login?.type === 'email'
    }
  },
  methods: {
    init() {
      const _this = this;
      _this.error = $store.state.auth.googleAuthError;
      $("#session-form").validate({
        ignore: [],
        rules: {
          password: {required: true},
        },
        messages: {
          password: {required: "Please enter your password"},
        },
        errorPlacement: function (error, element) {
          if (element.is("select:hidden")) {
            error.insertAfter(element.next(".nice-select"));
            $(element.next(".nice-select")).addClass("error-select");
          } else {
            error.insertAfter(element);
          }
        },
        submitHandler: _this.submitForm,
      });
    },
    handleSuccess(response) {
      $store.commit("auth/setToken", response.authToken);
      this.onSuccess(response.authToken)
      this.$el.parentNode.removeChild(this.$el);
    },
    handleError(error) {
      if (error?.response?.data?.message) {
        this.error = error?.response?.data?.message;
      } else {
        this.error = "An error occurred. Please try again later";
      }
    },
    submitForm(_, e) {
      this.error = "";
      this.isLoading = true;
      const form = new FormData(e.target);
      const formData = Object.fromEntries(form.entries());

      $store.dispatch("auth/loginUser", {...formData, email: $store.state.auth.user.email})
          .then(this.handleSuccess)
          .catch(this.handleError)
          .finally(() => {
            this.isLoading = false;
            $("#lottie_close").trigger("click");
            $(".login-modal").css("display", "flex")

          });
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.init();
    });
  },
};
</script>
