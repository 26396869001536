import httpClient from "./httpClient";

import {
  ACCOUNT_SETTINGS_RESET_PASSWORD_URL,
  ACCOUNT_SETTINGS_UPLOAD_PROFILE_URL,
  ACCOUNT_SETTINGS_USER_DATA_URL,
  ADD_PHONE_NUMBER_URL,
  AUTH_ROUTE_URL,
  CALCULATE_WITHDRAWAL_URL,
  CHECK_ACCOUNT_STATUS_URL,
  CHECK_AUTH_STATUS_URL,
  CHECKLIST_URL,
  COMPOUND_INTEREST_CALCULATOR_URL,
  CONTACT_US_URL,
  ACTIVATE_GOAL_URL,
  DELETE_GOAL_URL,
  EMERGENCY_FUND_UPDATE_URL,
  EMERGENCY_FUND_URL,
  CREATE_A_FINANACIAL_FREEDOM_GOAL,
  FINANCIAL_FREEDOM_CALCULATOR_URL,
  FINANCIAL_FREEDOM_CONFIG_URL,
  FINANCIAL_FREEDOM_MILESTONE_URL,
  FINANCIAL_FREEDOM_NUMBER_UPDATE_URL,
  FINANCIAL_FREEDOM_RESULT_URL,
  FINANCIAL_FREEDOM_UPDATE_URL,
  FORGOT_PASSWORD_URL,
  IN_APP_NOTIFICATIONS_URL,
  GET_PLAN_URL,
  GET_RECEIPT_URL,
  GET_TOKEN_URL,
  GET_USER_SUBSCRIPTION_URL,
  GOAL_CALCULATE_ACHIEVE_DATE_URL,
  GOAL_CALCULATOR_UPDATE_URL,
  GOAL_CALCULATOR_URL,
  GOAL_GET_SUGGESTED_MONTHLY_SAVINGS_URL,
  GOAL_GET_URL,
  GOAL_GROUP_URL,
  GOAL_TYPES_URL,
  GOOGLE_SIGNUP_CONTINUE_URL,
  GOOGLE_SIGNUP_URL,
  KYC_LOAD_DATA_URL,
  KYC_SEND_OTP_URL,
  KYC_UPDATE_NEXT_OF_KIN_URL,
  KYC_UPLOAD_BANK_URL,
  KYC_UPLOAD_ID_URL,
  KYC_UPLOAD_KRA_URL,
  KYC_UPLOAD_PHONE_URL,
  KYC_URL,
  KYC_VERIFY_OTP_URL,
  LOAD_DASHBOARD_GOALS_URL,
  LOAD_DATA_URL,
  LOAD_GOALS_URL,
  LOAD_WITHDRAWAL_URL,
  LOGIN_URL,
  MAKE_A_TRANSACTION_URL,
  MY_NOTIFICATIONS_URL,
  ONBOARDING_FINANCIAL_FREEDOM_URL,
  PAY_CARD_URL,
  PAY_MPESA_URL,
  PMT_CALCULATOR_URL,
  REQUEST_WITHDRAWAL_URL,
  RESEND_VERIFICATION_EMAIL_URL,
  RESET_PASSWORD_URL,
  RESET_PASSWORD_VALIDATE_URL,
  SIGNUP_URL,
  SUBMIT_INVESTMENT_DETAILS_URL,
  UPDATE_FINANCE_URL,
  UPDATE_USER_STAGE_URL,
  USER_DETAILS_URL,
  VERIFY_EMAIL_CODE_URL,
  WISHLIST_ADD_URL,
  WISHLIST_NPER_URL,
  GET_USER_TRANSACTION_URL,
  STATEMENTS_URL,
  SUBSCRIPTION_STATUS_URL,
  SUBSCRIPTION_DISMISS_URL, BILLING_URL, LOGOUT_URL
} from "./url";

export async function getInAppNotificationsRequest() {
  const response = await httpClient.get(IN_APP_NOTIFICATIONS_URL);
  return response.data;
}

export async function getSubscriptionStatusRequest() {
  const response = await httpClient.get(SUBSCRIPTION_STATUS_URL);
  return response.data;
}

export async function dismissSubscriptionReminderRequest() {
  const response = await httpClient.post(SUBSCRIPTION_DISMISS_URL);
  return response.data;
}

export async function getPlanRequest() {
  const response = await httpClient.get(GET_PLAN_URL, { cache: true });
  return response.data;
}

export async function getUserTransactionRequest(payload) {
  const response = await httpClient.post(GET_USER_TRANSACTION_URL, payload, { cache: true });
  return response.data;
}

export async function loadGoalsRequest() {
  const response = await httpClient.get(LOAD_GOALS_URL);
  return response.data;
}

export async function loadDashboardGoalsRequest() {
  const response = await httpClient.get(LOAD_DASHBOARD_GOALS_URL);
  return response.data;
}

export async function loadChecklistRequest(cache= true) {
  const response = await httpClient.get(CHECKLIST_URL, { cache });
  return response.data;
}

export async function goalCalculatorRequest(payload) {
  const response = await httpClient.post(GOAL_CALCULATOR_URL, payload);
  return response.data;
}

export async function getGoalDataRequest(payload) {
  const response = await httpClient.post(GOAL_GET_URL, payload);
  return response.data;
}

export async function goalCalculatorUpdateRequest(payload) {
  const response = await httpClient.post(GOAL_CALCULATOR_UPDATE_URL, payload);
  return response.data;
}

export async function emergencyFundRequest(payload) {
  const response = await httpClient.post(EMERGENCY_FUND_URL, payload);
  return response.data;
}

export async function emergencyFundUpdateRequest(payload) {
  const response = await httpClient.post(EMERGENCY_FUND_UPDATE_URL, payload);
  return response.data;
}

export async function updateFinanceRequest(payload) {
  const response = await httpClient.post(UPDATE_FINANCE_URL, payload);
  return response.data;
}

export async function financialFreedomUpdateRequest(payload) {
  const response = await httpClient.post(FINANCIAL_FREEDOM_UPDATE_URL, payload);
  return response.data;
}

export async function wishlistNperRequest(payload) {
  const response = await httpClient.post(WISHLIST_NPER_URL, payload);
  return response.data;
}

export async function wishlistAddRequest(payload) {
  const response = await httpClient.post(WISHLIST_ADD_URL, payload);
  return response.data;
}

export async function goalActivateRequest(payload) {
  const response = await httpClient.post(ACTIVATE_GOAL_URL, payload);
  return response.data;
}

export async function goalDeleteRequest(payload) {
  const response = await httpClient.post(DELETE_GOAL_URL, payload);
  return response.data;
}

export async function multiAssetGoalsRequest() {
  const response = await httpClient.get(GOAL_GROUP_URL);
  return response.data;
}

export async function loginRequest(payload) {
  const response = await httpClient.post(LOGIN_URL, payload);
  return response.data;
}

export async function logoutRequest() {
  const response = await httpClient.post(LOGOUT_URL);
  return response.data;
}
export async function signUpRequest(payload) {
  const response = await httpClient.post(SIGNUP_URL, payload);
  return response.data;
}

export async function googleAuthUrlRequest(params) {
  const response = await httpClient.get(GOOGLE_SIGNUP_URL, {
    params,
  });
  return response.data;
}

export async function googleSignUpContinueRequest(payload) {
  const response = await httpClient.post(GOOGLE_SIGNUP_CONTINUE_URL, payload);
  return response.data;
}

export async function getAuthRedirectRouteUrlRequest() {
  const response = await httpClient.get(AUTH_ROUTE_URL);
  return response.data;
}

export async function resetPasswordRequest(payload) {
  const response = await httpClient.post(RESET_PASSWORD_URL, payload);
  return response.data;
}

export async function validatePasswordResetTokenRequest(payload) {
  const response = await httpClient.post(RESET_PASSWORD_VALIDATE_URL, payload);
  return response.data;
}

export async function userDetailsRequest(payload) {
  const response = await httpClient.post(USER_DETAILS_URL, payload);
  return response.data;
}

export async function verifyEmailCodeRequest(payload) {
  const response = await httpClient.post(VERIFY_EMAIL_CODE_URL, payload);
  return response.data;
}

export async function resendEmailBackupRequest(payload) {
  const response = await httpClient.post(
    RESEND_VERIFICATION_EMAIL_URL,
    payload
  );
  return response.data;
}

export async function checkAccountStatusRequest() {
  const response = await httpClient.get(CHECK_ACCOUNT_STATUS_URL, {
    cache: true,
  });
  return response.data;
}

export async function generateReceiptRequest(payload) {
  const response = await httpClient.post(GET_RECEIPT_URL, payload);
  return response.data;
}

export async function paywithMpesaRequest(payload) {
  const response = await httpClient.post(PAY_MPESA_URL, payload);
  return response.data;
}

export async function paywithCardRequest(payload) {
  const response = await httpClient.post(PAY_CARD_URL, payload);
  return response.data;
}

export async function kycRequest(payload) {
  const response = await httpClient.post(KYC_URL, payload);
  return response.data;
}

export async function submitInvestmentDetailsRequest(payload) {
  const response = await httpClient.post(
    SUBMIT_INVESTMENT_DETAILS_URL,
    payload
  );
  return response.data;
}

export async function checkAuthStatusRequest() {
  const response = await httpClient.get(CHECK_AUTH_STATUS_URL);
  return response.data;
}

export async function getTokenRequest() {
  const response = await httpClient.get(GET_TOKEN_URL);
  return response.data;
}

export async function sendForgotPasswordEmailRequest(payload) {
  const response = await httpClient.post(FORGOT_PASSWORD_URL, payload);
  return response.data;
}

export async function getUserDataRequest() {
  const response = await httpClient.get(LOAD_DATA_URL);
  return response.data;
}

export async function calculateFinancialFreedomRequest(params) {
  const response = await httpClient.get(FINANCIAL_FREEDOM_CALCULATOR_URL, {
    params,
  });
  return response.data;
}

export async function createFinancialFreedomGoal(payload) {
  const response = await httpClient.post(CREATE_A_FINANACIAL_FREEDOM_GOAL, payload);
  return response.data;
}

export async function calculatePmtRequest(params) {
  const response = await httpClient.get(PMT_CALCULATOR_URL, {
    params,
  });
  return response.data;
}

export async function getFinancialFreedomMilestones() {
  const response = await httpClient.get(FINANCIAL_FREEDOM_MILESTONE_URL);
  return response.data;
}

export async function makeTransactionRequest(payload) {
  const response = await httpClient.post(MAKE_A_TRANSACTION_URL, payload);
  return response.data;
}

export async function updateFinancialFreedomAllocationRequest(payload) {
  const response = await httpClient.post(
    FINANCIAL_FREEDOM_NUMBER_UPDATE_URL,
    payload
  );
  return response.data;
}

export async function getFinancialParamsRequest() {
  const response = await httpClient.get(FINANCIAL_FREEDOM_CONFIG_URL);
  return response.data;
}

export async function onboardingFinancialFreedomCalculatorRequest(payload) {
  const response = await httpClient.post(
    ONBOARDING_FINANCIAL_FREEDOM_URL,
    payload
  );
  return response.data;
}

export async function financialFreedomResultRequest() {
  const response = await httpClient.get(FINANCIAL_FREEDOM_RESULT_URL);
  return response.data;
}

export async function accountSettingsUserDataRequest() {
  const response = await httpClient.get(ACCOUNT_SETTINGS_USER_DATA_URL);
  return response.data;
}

export async function accountSettingsResetPasswordRequest(payload) {
  const response = await httpClient.post(
    ACCOUNT_SETTINGS_RESET_PASSWORD_URL,
    payload
  );
  return response.data;
}

export async function accountSettingsUploadProfileRequest(payload) {
  const response = await httpClient.post(
    ACCOUNT_SETTINGS_UPLOAD_PROFILE_URL,
    payload
  );
  return response.data;
}

export async function kycLoadDataRequest() {
  const response = await httpClient.get(KYC_LOAD_DATA_URL);
  return response.data;
}

export async function kycSendOtpRequest(payload) {
  const response = await httpClient.post(KYC_SEND_OTP_URL, payload);
  return response.data;
}

export async function kycVerifyOtpRequest(payload) {
  const response = await httpClient.post(KYC_VERIFY_OTP_URL, payload);
  return response.data;
}

export async function kycUploadKraRequest(payload) {
  const response = await httpClient.post(KYC_UPLOAD_KRA_URL, payload);
  return response.data;
}

export async function kycUploadBankRequest(payload) {
  const response = await httpClient.post(KYC_UPLOAD_BANK_URL, payload);
  return response.data;
}

export async function kycUploadIdRequest(payload) {
  const response = await httpClient.post(KYC_UPLOAD_ID_URL, payload);
  return response.data;
}

export async function kycUploadPhotoRequest(payload) {
  const response = await httpClient.post(KYC_UPLOAD_PHONE_URL, payload);
  return response.data;
}

export async function kycUpdateNextOfKinRequest(payload) {
  const response = await httpClient.post(KYC_UPDATE_NEXT_OF_KIN_URL, payload);
  return response.data;
}

export async function calculateWithdrawalRequest(payload) {
  const response = await httpClient.post(CALCULATE_WITHDRAWAL_URL, payload);
  return response.data;
}

export async function requestWithdrawalRequest(payload) {
  const response = await httpClient.post(REQUEST_WITHDRAWAL_URL, payload);
  return response.data;
}

export async function getSubscriptionDetailsRequest() {
  const response = await httpClient.get(GET_USER_SUBSCRIPTION_URL);
  return response.data;
}

export async function loadWithdrawalRequest() {
  const response = await httpClient.get(LOAD_WITHDRAWAL_URL);
  return response.data;
}

export async function getNotificationsRequest() {
  const response = await httpClient.get(MY_NOTIFICATIONS_URL, {
    cache: true,
  });
  return response.data;
}

export async function compoundInterestCalculatorRequest(payload) {
  const response = await httpClient.post(
    COMPOUND_INTEREST_CALCULATOR_URL,
    payload
  );
  return response.data;
}

export async function getSuggestedMonthlySavingsRequest(payload) {
  const response = await httpClient.post(
    GOAL_GET_SUGGESTED_MONTHLY_SAVINGS_URL,
    payload
  );
  return response.data;
}

export async function calculateGoalAchieveDateRequest(payload) {
  const response = await httpClient.post(
    GOAL_CALCULATE_ACHIEVE_DATE_URL,
    payload
  );
  return response.data;
}

export async function getGoalTypesRequest() {
  const response = await httpClient.get(GOAL_TYPES_URL);
  return response.data;
}

export async function addPhoneNumberRequest(payload) {
  const response = await httpClient.post(ADD_PHONE_NUMBER_URL, payload);
  return response.data;
}

export async function sendContactUsRequest(payload) {
  const response = await httpClient.post(CONTACT_US_URL, payload);
  return response.data;
}

export async function updateUserStageRequest(payload) {
  const response = await httpClient.post(UPDATE_USER_STAGE_URL, payload);
  return response.data;
}

export async function listStatementsRequest(params) {
  const response = await httpClient.get(STATEMENTS_URL.LIST, { params });
  return response.data;
}
export async function downloadStatementRequest(statementRequest) {
  const response = await httpClient.get(STATEMENTS_URL.DOWNLOAD.replace('{statementRequest}', statementRequest),
      {
        responseType: 'arraybuffer',
      });
  return response.data;
}
export async function checkStatementAvailabilityRequest() {
    const response = await httpClient.get(STATEMENTS_URL.AVAILABILITY);
    return response.data;
}

export async function requestStatementRequest(payload) {
  const response = await httpClient.post(STATEMENTS_URL.REQUEST, payload);
  return response.data;
}

export async function getStatementStatusRequest(statementRequest) {
  const response = await httpClient.get(STATEMENTS_URL.STATUS.replace('{statementRequest}', statementRequest));
  return response.data;
}

  export const billingRequests = {
  subscription: {
    summary: async () => {
      const response = await httpClient.get(BILLING_URL.SUBSCRIPTION.SUMMARY);
      return response.data;
    },
    billingHistory: async (params) => {
        const response = await httpClient.get(BILLING_URL.SUBSCRIPTION.BILLING_HISTORY_SUMMARY, { params });
        return response.data;
    },
    premiumMilestone: async () => {
      const response = await httpClient.get(BILLING_URL.SUBSCRIPTION.GET_PREMIUM_MILESTONE);
      return response.data;
    }
  }
}

